.roomsFacility {
    overflow: hidden;
  }
  
  .roomsFacility .container {
    max-width: 1200px;
  }
  
  .roomsFacility .roomCard {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    position: relative;
    box-shadow: 0px 0px 20px 0px rgb(15 15 43 / 10%);
  }
  
  .roomsFacility .roomCard .RoomsImage {
    width: 100%;
    height: 510px;
    max-width: 400px;
    object-fit: cover;
  }
  
  .roomsFacility .roomCard .RoomsImage img {
    width: 100%;
    height: 100%;
  }
  
  .roomsFacility .roomCard .roomText {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0%;
    background-color: transparent;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0.3) -201%,
      #ffffff 100%
    );
  }
  
  .roomsFacility .roomCard .roomText h2 {
    text-align: center;
    color:#008000;
    font-family: "Platypi", serif;
    font-weight: 400 !important;
    padding: 10px;
    font-weight: 700;
  }
  
  .roomsFacility .roomCard .titleSection {
    position: relative;
    top: 30% !important;
    left: 0;
    text-align: center;
  }
  
  .roomsFacility .roomCard .roomText p {
    text-align: center;
    color: #111;
    font-family: var(---robotFont);
    padding: 15px;
  }
  
  .villclas img{
    height: 540px !important;
  }
  
  
  /* ======================================== Fcilities ==================================== */
  
  