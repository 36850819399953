.homeSroll {
    background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
      url("../media//contactbg.jpg");
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px -335px;
    object-fit: cover;
    
    overflow: hidden;
  }
  
  .homeScroll1 {
    background: linear-gradient(
        to left,
        rgb(255, 255, 255, 0.2),
        rgb(255, 255, 255, 0)
      ),
      url();
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
  }
  
  .homeSection {
    overflow: hidden;
    width: 100%;
    height: 85vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px !important;
    flex-direction: column;
  }
  
  .homeSection h1 {
    text-align: center;
    font-size: 5rem;
    font-family: "Platypi", serif;
    font-weight: 400;
    color: #fff !important;
    margin: 0;
    animation: mymove 2s;
  }
  
  @keyframes mymove {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 500px) {
    .homeScroll {
      background-repeat: no-repeat;
      background-position: 36% 61%;
    }
    .homeSection {
      overflow: hidden;
      width: 100%;
    }
    .homeSection h1 {
      text-align: center;
      font-size: 2.6rem;
     
      font-family: "Platypi", serif;
  font-weight: 400;
      color: #fff;
      margin: 0;
      animation: mymove 2s;
    }
    @keyframes mymove {
      0% {
        opacity: 0;
        transform: translateY(100px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .homeSection p {
    text-align: center;
    font-size: 1rem;
    font-family: var(---popinsFont);
    font-weight: 500;
    color: #eee;
    animation: mymove1 2s;
  }
  
  @keyframes mymove1 {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .homeSection ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .homeSection ul li span {
    font-size: 1rem;
    color: var(---secondColor);
    margin: 3px;
  }
  
  .buttonnav span {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    border: 2px solid  #a52a2a;
    border-radius: 20px;
    background-color:#a52a2a !important;
    font-family: var(---popinsFont);
    padding: 6px 32px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .homeText .buttonnav a,
  .buttonnav a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    border: 2px solid #683e2a !important;
    border-radius: 20px;
    background-color:  #683e2a !important;
    font-family: var(---popinsFont);
    padding: 6px 32px;
    text-transform: uppercase;
  }
  
  .homeText .buttonnav a:hover,
  .buttonnav a:hover {
    background-color: var(---secondColor);
    border: 2px solid var(---secondColor);
    color: #fff;
  }
  
  .serviceSection {
    background: rgb(0, 0, 0, 0.5);
    padding: 50px 0;
    position: relative;
    overflow: unset;
  }
  
  .serviceSection:before {
    content: "";
    position: absolute;
    width: 1.5px;
    height: 4rem;
    background: goldenrod;
    top: 0;
    left: 50%;
    transform: translateY(-50%);
  }
  
  .serviceSection:after {
    content: "";
    position: absolute;
    width: 1.5px;
    height: 4rem;
    background: goldenrod;
    bottom: -50px;
    left: 50%;
    transform: translateY(-50%);
  }
  
  .serviceSection .container {
    max-width: 1200px;
  }
  
  .serviceSection p.titleText {
    margin: 15px 0;
  }
  
  .serviceSection p {
    color: #fff;
    text-align: justify;
    padding: 0 30px;
    margin: 0;
  }
  
  @media (max-width: 500px) {
    .homeSection p {
      text-align: center;
      font-size: 0.8rem;
      font-family: var(---popinsFont);
      font-weight: 500;
    }
    .serviceSection::after {
      display: none;
    }
    .serviceSection:before {
      display: none;
    }
  }
  

  @media (max-width: 651px){
    .homeSection {
      overflow: hidden;
      height: 341px !important;
      width: 100%;
  }}
@media (min-width:446px){
  .homeSection {
    overflow: hidden;
    height: 341px;
    width: 100%;
}

}



  .homeSroll {
    /* background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)), url(http://localhost:3001/static/media/gallery-img.99daba7….jpg); */
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    object-fit: cover;
    overflow: hidden;
}
