

.imagee img{
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
    color: white;

}



.activityCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    height: 100% !important;
    border: 1px solid var(---secondColor);
    background-color: #fff;
    backdrop-filter: blur(5px);
  }
  
  .activityCard .activityIcon:hover {
    transform: rotate(225deg);
    transition: 0.5s ease-in-out;
  }
  
  .activityCard .activityIcon span {
    font-size: 2.6rem;
    font-weight: 500;
    color: green;
  }
  
  .activityCard .activityTittle h4 {
    color: green !important;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 10px 0;
    text-align: center;
  }
  


  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .dropdown-menu li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background-color: #ddd;
  }
  
  .book-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
   
  }
  

  .swiper-button-next:after{
    color:white;
  }

  @media (max-width: 320px){

    
.imagee img{
  top: 0;
  left: 0;
  width: 300px;
  object-fit: cover;
  
}
    }

@media (min-width:700px){
    
}

.material-symbols-outlined{
  color: #008000;
  ;
}

 /* aaaaaaaaaaaaaaaaa-------banner css -----aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */
 @media (min-width: 701px) {
  
  .homeScrollImage-one {
    background-size: cover;
    background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
    url(../media/omkae-image-.jpg);
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: center;
    /* background-repeat: no-repeat; */
    background-position: 0px -632px !important;
    overflow: hidden;
}}
@media (max-width: 700px) {
  .homeScrollImage-one {
    background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
      url(../media/eventBigImg.jpg);
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: center;
    background-repeat: no-repeat;
    /* background-position: 0px -623px !important; */
    overflow: hidden;
  }
}

 .homeScrollImage-two {
  background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
    url("../media/eventBigImg.jpg");
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  /* background-repeat: no-repeat; */
  background-position: 0px -88px;
  overflow: hidden;
}
 .homeScrollImage-three {
  background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
    url(https://lh3.googleusercontent.com/p/AF1QipOaulQZmzeVLfrmI3eSdBNHwtcvEP1N9snrB4Zl=s1360-w1360-h1020);
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  /* background-position: 0px -88px; */
  background-position: center top;
  overflow: hidden;
}

.homeScroll1 {
  background: linear-gradient(
      to left,
      rgb(255, 255, 255, 0.2),
      rgb(255, 255, 255, 0)
    ),
    url();
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center top; */
  overflow: hidden;
}

.homeSection {
  overflow: hidden;
  width: 100%;
  height: 85vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.homeSection h1 {
  text-align: center;
  font-size: 5rem;
  font-family: "Platypi", serif;
  font-weight: 500;
  color: #fff;
  margin: 0;
  animation: mymove 2s;
}

@keyframes mymove {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 500px) {
  .homeScroll {
    background-repeat: no-repeat;
    background-position: 36% 61%;
  }
  .homeSection {
    overflow: hidden;
    width: 100%;
  }
  .homeSection h1 {
    text-align: center;
    font-size: 2.6rem;
    font-family: "Platypi", serif;
    font-weight: 500;
    color: #fff;
    margin: 0;
    animation: mymove 2s;
  }
  @keyframes mymove {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.homeSection p {
  text-align: center;
  font-size: 1rem;
  font-family: var(---popinsFont);
  font-weight: 500;
  color: #eee;
  animation: mymove1 2s;
}

@keyframes mymove1 {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.homeSection ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.homeSection ul li span {
  font-size: 1rem;
  color: var(---secondColor);
  margin: 3px;
}

.buttonnav span {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  border: 2px solid  #a52a2a;
  border-radius: 20px;
  background-color:#a52a2a !important;
  font-family: var(---popinsFont);
  padding: 6px 32px;
  text-transform: uppercase;
  cursor: pointer;
}

.homeText .buttonnav a,
.buttonnav a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  border: 2px solid #683e2a !important;
  border-radius: 20px;
  background-color:  #683e2a !important;
  font-family: var(---popinsFont);
  padding: 6px 32px;
  text-transform: uppercase;
}

.homeText .buttonnav a:hover,
.buttonnav a:hover {
  background-color: var(---secondColor);
  border: 2px solid var(---secondColor);
  color: #fff;
}

.serviceSection {
  background: rgb(0, 0, 0, 0.5);
  padding: 50px 0;
  position: relative;
  overflow: unset;
}

.serviceSection:before {
  content: "";
  position: absolute;
  width: 1.5px;
  height: 4rem;
  background: goldenrod;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
}

.serviceSection:after {
  content: "";
  position: absolute;
  width: 1.5px;
  height: 4rem;
  background: goldenrod;
  bottom: -50px;
  left: 50%;
  transform: translateY(-50%);
}

.serviceSection .container {
  max-width: 1200px;
}

.serviceSection p.titleText {
  margin: 15px 0;
}

.serviceSection p {
  color: #fff;
  text-align: justify;
  padding: 0 30px;
  margin: 0;
}

@media (max-width: 500px) {
  .homeSection p {
    text-align: center;
    font-size: 0.8rem;
    font-family: var(---popinsFont);
    font-weight: 500;
   
  }
}

/* @media (max-width: 500px) {
  
  .homeScrollImage-one {
   
    background-position: 0px -201px !important;
   
}
  } */


@media (max-width: 500px) {
  
  .homeScrollImage-two {
    /* background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)), url(http://localhost:3001/static/media/eventchild.043dccc….jpg); */
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
    overflow: hidden;
}
  }

@media (max-width: 1440px) {
  
  .homeScrollImage-two {
    /* background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)), url(http://localhost:3001/static/media/eventchild.043dccc….jpg); */
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: unset;
    background-position: unset;
    overflow: hidden;
}




  .serviceSection::after {
    display: none;
  }
  .serviceSection:before {
    display: none;
  }
}

