.facilitySection {
    margin-top: 30px;
  }
  
  .failityBanner {
    min-height: 450px;
  }
  
  .facilityCardBg {
    width: 100%;
    height: 100%;
    background-color: #fff;
    min-height: 400px;
    transform: scale(1.1);
    box-shadow: 0px 0px 20px 0px rgb(15 15 43 / 10%);
  }
  
  .facilitiesCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  
  .facilitiesText {
    margin: 20px 0;
  }
  
  .facilitiesText p {
    text-align: justify;
  }
  
  .facilitiesText h2 {
    font-size: 1.8rem;
    color:#008000 !important;
    font-family: "Platypi", serif;
    font-weight: 400;
    text-transform: capitalize;
    margin: 0;
  }
  
  .facilitiesText p {
    color: #111;
    font-weight: normal;
    font-size: 1.1rem;
    text-transform: capitalize;
    margin: 0;
  }
  
  .facilitySection .attractionBody {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(0, 0, 0, 0.5);
    bottom: -100%;
    opacity: 0;
    transition: 0.5s ease-in-out;
    padding: 10px;
  }
  
  .facilitySection .attrectionCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    box-shadow: 0 5px 7px #808080;
    transition: 0.5s ease-in-out;
    overflow: hidden;
    height: 400px;
  }
  
  .facilitySection .attrectionImg {
    width: 100%;
    height: 100%;
  }
  
  .facilitySection .attrectionImg img {
    width: 100%;
    height: 100%;
    min-width: 350px;
    object-fit: cover;
    max-height: 350px;
  }
  
  .facilitySection .attrectionImg h3 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: fit-content;
    padding: 10px 15px;
    background-color: rgb(0, 0, 0, 0.5);
    margin: 0;
    text-align: center;
    transition: 0.5s ease-in-out;
    color: #fff;
    font-size: 1.3rem;
  }
  
  .facilitySection .attractionBody {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(0, 0, 0, 0.5);
    bottom: -100%;
    opacity: 0;
    transition: 0.5s ease-in-out;
    padding: 10px;
  }
  
  .facilitySection .attractionBody h3 {
    text-align: center;
    font-weight: 500;
    color: var(---secondColor);
    font-size: 1.4rem;
    font-family: "Platypi", serif;
    font-weight: 400;
  }
  
  .facilitySection .attractionBody p {
    text-align: center;
    color: #eee;
    font-size: 0.9rem;
  }
  
  .facilitySection .attractionBody ul {
    color: #fff;
    font-size: 1rem;
  }
  
  .attrectionCard:hover .attrectionImg h3 {
    visibility: hidden;
    opacity: 0;
    transition: 0.5s ease-in-out;
  }
  
  .attrectionCard:hover .attractionBody {
    bottom: 0;
    transition: 0.5s ease-in-out;
    opacity: 1;
  }
  
  .facilitySection .facilityimage {
    width: 100%;
    height: 350px;
  }
  
  .facilitySection .facilityimage img {
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 500px) {
    .facilityCardBg {
      width: 100%;
      height: 100%;
      background-color: #fff;
      min-height: 350px;
      transform: scale(1.1);
      box-shadow: 0px 0px 20px 0px rgb(15 15 43 / 10%);
    }
  }
  
  .birthdayevent  h2 {
    font-size: 1.8rem;
    color:  var(---primeColor);;
    font-family: "Platypi", serif;
    font-weight: 400;
    text-transform: capitalize;
    margin: 20px 0;
  }


  .titleSectio h2:after {
    content: "";
    position: absolute;
    width: 6rem;
    height: 2px;
    background: var(---secondColor);
    border-radius: 50px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 100px;
  }
  


  /* ************************************************************BANNER CSS***************************************************************************** */


  .homeScrol {
    background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
      url(https://evermoreresort.com/sites/default/files/styles/rectangle_large/public/2022-09/st._andrews_patio_lights_0.png.webp?itok=s_6JCMK0);
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
  }
  
  .homeScrol1 {
    background: linear-gradient(
        to left,
        rgb(255, 255, 255, 0.2),
        rgb(255, 255, 255, 0)
      ),
      url();
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    overflow: hidden;
  }
  
  .homeSection {
    overflow: hidden;
    width: 100%;
    height: 85vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .homeSection h1 {
    text-align: center;
    font-size: 5rem;
    font-family: "Platypi", serif;
  font-weight: 400 !important;
    color: #fff;
    margin: 0;
    animation: mymove 2s;
  }
  
  @keyframes mymove {
    0% {
      opacity: 0;
      transform: translateY(100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 500px) {
    .homeScroll {
      background-repeat: no-repeat;
      background-position: 36% 61%;
    }
    .homeSection {
      overflow: hidden;
      width: 100%;
    }
    .homeSection h1 {
      text-align: center;
      font-size: 2.6rem;
      font-family: "Platypi", serif;
      font-weight: 400;
      color: #fff;
      margin: 0;
      animation: mymove 2s;
    }
    @keyframes mymove {
      0% {
        opacity: 0;
        transform: translateY(100px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .homeSection p {
    text-align: center;
    font-size: 1rem;
    font-family: var(---popinsFont);
    font-weight: 500;
    color: #eee;
    animation: mymove1 2s;
  }
  
  @keyframes mymove1 {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .homeSection ul {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  
  .homeSection ul li span {
    font-size: 1rem;
    color: var(---secondColor);
    margin: 3px;
  }
  
  .buttonnav span {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    border: 2px solid #d6a063;
    border-radius: 20px;
    background-color: #d6a063;
    font-family: var(---popinsFont);
    padding: 6px 32px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .homeText .buttonnav a,
  .buttonnav a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    border: 2px solid #d6a063;
    border-radius: 20px;
    background-color: #d6a063;
    font-family: var(---popinsFont);
    padding: 6px 32px;
    text-transform: uppercase;
  }
  
  .homeText .buttonnav a:hover,
  .buttonnav a:hover {
    background-color: var(---secondColor);
    border: 2px solid var(---secondColor);
    color: #fff;
  }
  
  .serviceSection {
    background: rgb(0, 0, 0, 0.5);
    padding: 50px 0;
    position: relative;
    overflow: unset;
  }
  
  .serviceSection:before {
    content: "";
    position: absolute;
    width: 1.5px;
    height: 4rem;
    background: goldenrod;
    top: 0;
    left: 50%;
    transform: translateY(-50%);
  }
  
  .serviceSection:after {
    content: "";
    position: absolute;
    width: 1.5px;
    height: 4rem;
    background: goldenrod;
    bottom: -50px;
    left: 50%;
    transform: translateY(-50%);
  }
  
  .serviceSection .container {
    max-width: 1200px;
  }
  
  .serviceSection p.titleText {
    margin: 15px 0;
  }
  
  .serviceSection p {
    color: #fff;
    text-align: justify;
    padding: 0 30px;
    margin: 0;
  }
  
  @media (max-width: 500px) {
    .homeSection p {
      text-align: center;
      font-size: 0.8rem;
      font-family: var(---popinsFont);
      font-weight: 500;
    }
    .serviceSection::after {
      display: none;
    }
    .serviceSection:before {
      display: none;
    }
  }
  
  

  