/* NAVBAR CSS */

.navbar {
    z-index: 100;
    background: #fff;
    padding: 0 0;
  }

  .navbar .nav-item .nav-link {
    font-size: 1.2rem;
    color: rgb(153, 75, 20) !important;
    /* font-family: var(---OswaldFont); */
    font-weight: 500;
    transition: 0.4s ease-in-out;
    text-transform: capitalize;
}
  
  .sticky {
    position: fixed;
    top: 0;
    transition: 0.5s ease all;
    background: #fff;
    box-shadow: 0 3px 7px #808080;
  }
  
  .navbar-brand {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 90px;
    max-height: 90px;
    transform: scale(1.2);
    margin: 0;
    padding: 0;
  }
  
  .navbar-brand img {
    width: 100%;
    height: 100%;
  }
  
  .navbar > .container,
  .navbar > .container-fluid,
  .navbar > .container-lg,
  .navbar > .container-md,
  .navbar > .container-sm,
  .navbar > .container-xl,
  .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-brand img {
    width: 100%;
    height: 100%;
  }
  
  .navbar-nav li.nav-item {
    position: relative;
    cursor: pointer;
  }
  
  .navbar .nav-item {
    padding: 0px 18px;
  }
  
  .navbar .nav-item .nav-link {
    font-size: 1.2rem;
    color: var(---primeColor);
    /* font-family: var(---OswaldFont); */
    font-weight: 500;
    transition: 0.4s ease-in-out;
    text-transform: capitalize;
  }
  
  .sticky .nav-item .nav-link {
    color: #111;
  }
  
  .nav-item .nav-link.active {
    color: var(---primeColor);
  }
  
  .navbar .nav-item .nav-link::before {
    content: "";
    width: 0%;
    height: 2px;
    display: block;
    background-color: currentColor;
    margin-top: 5px;
    bottom: 0;
    left: 0;
    border-radius: 50px;
  }
  
  .nav-item .nav-link.active::before,
  .navbar .nav-item .nav-link:hover::before {
    width: 100%;
    transition: 0.5s ease-in-out;
  }
  
  #secondNavbar .nav-item .nav-link {
      color: #111;
  }
  
  .navbar-collapse {
    flex-grow: inherit;
    justify-content: center;
  }
  
  .navbar-nav {
    position: relative;
    display: flex;
  }
  
  .navbar-icon {
    position: relative;
    display: flex;
    justify-content: center;
    transform: translateY(20%);
  }
  
  .nav-icon-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    position: relative;
  }
  
  .dropdown-menu {
    position: fixed;
    top: 60px;
    right: 5%;
    z-index: 1000;
  }
  
  .nav-icon {
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
    font-family: "Raleway", sans-serif;
    transition: 0.4s ease all;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .nav-icon span {
    padding: 0 5px;
    text-transform: capitalize;
  }
  
  .nav-icon:hover {
    color: #fff;
  }
  
  #menuToggle {
    display: none;
  }
  
  #afterLoginLink {
    display: none;
  }
  
  @media (max-width: 990px) {
    .navbar > .container,
    .navbar > .container-fluid,
    .navbar > .container-lg,
    .navbar > .container-md,
    .navbar > .container-sm,
    .navbar > .container-xl,
    .navbar > .container-xxl {
      display: flex;
      flex-wrap: inherit;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .navbar-brand {
      position: relative;
    }
    .navbar-brand .h1 {
      font-size: 1.6rem;
      line-height: 5px;
      font-weight: 600;
      text-align: left;
    }
    .navbar-brand p {
      font-size: 1rem;
      text-align: left;
    }
    .navbar-icon {
      right: 10px;
      position: absolute;
      transform: translateY(-50%);
    }
    .nav-icon-item {
      padding: 0 5px;
    }
    .navbar .navbar-nav > li > a:focus {
      outline: none !important;
      box-shadow: none;
    }
    .navbar-toggler-icon {
      display: block;
      position: relative;
    }
    .navbar-toggler-icon,
    .navbar-toggler-icon:before,
    .navbar-toggler-icon:after {
      width: 2rem;
      height: 2px;
      cursor: pointer;
      background: var(---primeColor);
      transition: transform 0.5s ease-in-out;
      left: 0px;
    }
    .sticky .navbar-toggler-icon,
    .sticky .navbar-toggler-icon:before,
    .sticky .navbar-toggler-icon:after {
      background-color: #111;
    }
    .navbar-toggler-icon:before,
    .navbar-toggler-icon:after {
      content: "";
      position: absolute;
      left: 0;
    }
    .navbar-toggler-icon:before {
      bottom: 6px;
    }
    .navbar-toggler-icon:after {
      top: 6px;
    }
    #navbarNav {
      height: fit-content;
      width: 300px;
      opacity: 0;
      position: fixed;
      z-index: 100;
      top: -100%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      transition: 0.5s ease;
      border-radius: 10px;
    }
    #navbarNav:after {
      font-family: "Material Icons";
      content: "\e5cd";
      position: absolute;
      top: -80px;
      right: 0;
      color: red;
      font-size: 2rem;
    }
    .backBlur {
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow: hidden;
      left: 0;
      top: -100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.3);
    }
    .topNavbar {
      left: 0;
    }
    #menuToggle:checked ~ #navbarNav {
      top: 50%;
    }
    #menuToggle:checked ~ .backBlur {
      top: 50%;
    }
    .navbar-nav {
      padding: 20px 0;
    }
    .navbar .nav-item {
      padding: 0 20px;
      text-align: center;
    }
    .navbar .nav-item .nav-link {
      padding: 5px 5px 15px 5px;
      text-decoration: none;
      font-size: 16px;
      color: var(---primeColor);
      display: block;
      transition: 0.3s;
      position: relative;
    }
    .nav-item .nav-link.active::before,
    .navbar .nav-item .nav-link:hover::before {
      width: 0%;
      transition: 0.5s ease-in-out;
    }
    .navbar .nav-item .nav-link.linkLine::before {
      content: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #808080;
      width: 100%;
      height: 1px;
    }
    #afterLoginLink {
      padding: 0;
      position: relative;
      display: block;
    }
    .navbar-toggler-icon-close {
      display: block;
      position: relative;
      position: absolute;
      right: 20px;
      top: 30px;
      cursor: pointer;
    }
    .navbar-toggler-icon-close:hover {
      transform: rotate(400deg);
    }
    .navbar-toggler-icon-close,
    .navbar-toggler-icon-close:before,
    .navbar-toggler-icon-close:after {
      width: 1.6rem;
      height: 2px;
      background: #111;
      transition: transform 0.5s ease-in-out;
      transform: rotate(45deg);
    }
    .navbar-toggler-icon-close:before,
    .navbar-toggler-icon-close:after {
      content: "";
      position: absolute;
      left: 0;
    }
    .navbar-toggler-icon-close:before {
      bottom: 6px;
      opacity: 0;
    }
    .navbar-toggler-icon-close:after {
      top: 0px;
      transform: rotate(90deg);
    }
  }
  
  @media (max-width: 500px) {
    .navbar-brand {
      position: relative;
      height: fit-content;
      transform: scale(1.2);
    }
  }

  /* NAVBAR CSS END */
  /* nav.css */








.navbar-collapse {
  margin-left: 96px !important;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-color: #fff !important;
  background-position: center;
  background-size: 100%;
}

.me-auto {
  margin-left: -100px !important;
}

.navbar-collapse {
  margin-left: 268px !important;
}

.navbar .nav-item .nav-link {
  color: #994b14 !important;
}
.navbar-collapse {
  margin-left: 101px !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.heeyy{
  margin-left: 10px !important;
}