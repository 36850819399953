.event-image{
    max-width: 610px;
    margin-top: 30px;
}
.event-image img{
    width: 100%;
    margin-left: 2px !important;
    
}


.birthdaydetail{
  color: black !important;
}
.titleSection h2 {
    font-size: 2.4rem;
    font-family: "Platypi", serif;
  font-weight: 400;
    text-transform: capitalize;
    color: #008000;
    position: relative;
    padding: 10px 0;
    margin-top: 10px;
    margin-left: 45px;
  }

  .titleSection p {
    /* max-width: 800px; */
    text-align: center;
    color: #111;
    font-weight: 500;
    font-size: 1rem;
  }

  .titleSection h2:after {
  content: "";
  position: absolute;
  width: 6rem;
  height: 2px;
  background: var(---secondColor);
  border-radius: 50px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;
}

.weedingevent{
    margin-top: 90px !important;
}

.homeSc {
  background: linear-gradient(to left, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.4)),
    url("../media//event-banner1.jpg");
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-repeat: no-repeat;
  background-position:center !important;
  overflow: hidden;
}









/* For screens smaller than 768px */
@media (max-width: 767.98px) {
  /* Adjust the layout for smaller screens */
  .col-lg-6 {
    width: 100%;
    margin-bottom: 20px; /* Adding margin between rows */
  }
  .event-image img {
    width: 100%;
    margin-left: -91px;
}


}

/* For screens between 768px and 991.98px */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* Adjust the layout for medium-sized screens */
  .col-lg-6 {
    width: 50%; /* Make each column take up 50% of the container width */
  }
}

/* For screens larger than 992px */
@media (min-width: 992px) {
  /* No specific changes needed for larger screens */
}


